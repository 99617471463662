import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoBilletLandscape, LogoBillet } from '../../assets/images';
import { ROUTES } from '../../routes/constants';
import { useAppContext } from '../../store/app-context/app-context';
import Icon from '../icon';
import { TIconName } from '../icon/types';
import Loader from '../../components/loader';
import Modal from '../../components/modal';
import {
  Container,
  StyledBilletLogo,
  StyledButton,
  StyledDiv,
  StyledHeader,
  StyledButtonText,
  ImageContainer,
  CloseSessionContainer,
  TextModalSession,
  Button,
  ButtonText,
  Text,
  MobileButton,
  HeaderResponsive,
  DialogMobileSession,
  CloseSessionMobileButton,
  ModalChild,
} from './styles';
import useLogoutUser from '../../services/login/use-logout';

export const Header: React.FC = () => {
  const [openSessionDialog, setOpenSessionDialog] = useState(false);
  const [openMobileSessionDialog, setOpenMobileSessionDialog] = useState(false);

  const history = useNavigate();
  const location = useLocation();
  const { user } = useAppContext();
  const { logoutUser, loading: loadingLogout } = useLogoutUser();

  const handleCloseSession = async () => {
    setOpenSessionDialog(false);
    const logout = async () => {
      try {
        await logoutUser();
      } catch (error) {
        throw new Error(error as string);
      }
    };
    logout();
  };

  if (loadingLogout) return <Loader isVisible />;
  console.log(user);

  return (
    <div data-testid="wrap-header">
      <StyledDiv>
        <StyledHeader isUserActive={user !== undefined}>
          <ImageContainer>
            <StyledBilletLogo src={LogoBilletLandscape} alt="Logo Billet" />
          </ImageContainer>
          {user && (
            <CloseSessionContainer
              data-testid="btn-open-close-session-dialog"
              onClick={() => setOpenSessionDialog(true)}>
              <Icon iconName={'closeSession' as TIconName} fill="red" />
              <Text>Cerrar Sesión</Text>
            </CloseSessionContainer>
          )}
        </StyledHeader>
        {location.pathname !== ROUTES.LOGIN && (
          <HeaderResponsive>
            <Container>
              <MobileButton
                data-testid="btn-mobile-burger-menu"
                onClick={() => setOpenMobileSessionDialog(!openMobileSessionDialog)}>
                <StyledBilletLogo src={LogoBillet} alt="Logo Billet" />
                <Icon iconName={'moreVertical' as TIconName} />
              </MobileButton>
              <StyledButton
                data-testid="btn-navigate-charges"
                onClick={() => {
                  history(ROUTES.CHARGES);
                }}
                isSelected={location.pathname === ROUTES.CHARGES}>
                <Icon iconName={'qr' as TIconName} fill="white" />
                <StyledButtonText>Cobrar</StyledButtonText>
              </StyledButton>
              <StyledButton
                data-testid="btn-navigate-sales"
                onClick={() => {
                  history(ROUTES.SALES);
                }}
                isSelected={location.pathname === ROUTES.SALES}>
                <Icon iconName={'chart' as TIconName} fill="white" />
                <StyledButtonText>Ventas</StyledButtonText>
              </StyledButton>
            </Container>
          </HeaderResponsive>
        )}
        {openMobileSessionDialog && (
          <DialogMobileSession data-testid="wrap-menu-mobile-session-dialog">
            <div className="wrap-user">
              <Icon iconName={'user' as TIconName} />
              <span className="text-wrap-user">
                {user.attributes.name} {user.attributes.family_name}
              </span>
            </div>
            <div>
              <CloseSessionMobileButton
                data-testid="btn-close-session-mobile"
                onClick={() => {
                  setOpenMobileSessionDialog(false);
                  setOpenSessionDialog(true);
                }}>
                <Icon iconName={'closeSessionMobileW' as TIconName} fill="white" />
                <span className="button-text-mobile-session">Cerrar Sesión</span>
              </CloseSessionMobileButton>
            </div>
          </DialogMobileSession>
        )}
      </StyledDiv>
      {openSessionDialog && (
        <Modal
          data-testid="modal-close-session"
          open={openSessionDialog}
          onClose={() => setOpenSessionDialog(false)}>
          <ModalChild>
            <TextModalSession>¿Estás seguro de cerrar la sesión?</TextModalSession>
            <Button data-testid="btn-close-session" onClick={() => handleCloseSession()}>
              <ButtonText>Cerrar sesión</ButtonText>
            </Button>
          </ModalChild>
        </Modal>
      )}
    </div>
  );
};

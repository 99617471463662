import React from 'react';
import { Icon } from '..';
import { TIconName } from '../icon/types';
import {
  StyledModalOverlay,
  CloseSessionWrapper,
  WrapperCloseDialog,
  CloseIconContainer,
} from './styles';
import { IModal } from './types';

const Modal: React.FC<IModal> = ({ children, open, onClose }) => {
  return (
    <StyledModalOverlay data-testid="wrap-modal" open={open}>
      <CloseSessionWrapper>
        <WrapperCloseDialog>
          <CloseIconContainer data-testid="btn-close" onClick={() => onClose()}>
            <Icon iconName={'error' as TIconName} fill="businessPrimary80" />
          </CloseIconContainer>
        </WrapperCloseDialog>
        <div data-testid="wrap-children">{children}</div>
      </CloseSessionWrapper>
    </StyledModalOverlay>
  );
};

export default Modal;

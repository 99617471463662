/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Loader } from '../../../../components';
import ErrorGeneric from '../../../../components/error-generic';
import { ROUTES } from '../../../../routes/constants';
import { useGetDynamicQr } from '../../../../services/dynamicQr/use-get-dynamic-qr';
import { StyledNumericPadCard } from '../../styles';
import { thousandSeparator } from '../../../../utils/movements-utils';

import {
  AmmountContainer,
  AmmountText,
  BodyText,
  StyledButtonContainer,
  CurrencyText,
  ImgStyled,
  QrGeneratorFooter,
  QrGeneratorHeader,
  Title,
  GoBackContainer,
  GoBackText,
} from './styles';

type TQrGeneraton = {
  amount: number;
  setShowQrGenerator: (arg: boolean) => void;
};

const QrGenerator = ({ amount, setShowQrGenerator }: TQrGeneraton) => {
  const [dynamicQr, setDynamicQr] = useState('');
  const { getDynamicQr, loading, data } = useGetDynamicQr();
  const navigate = useNavigate();

  const thousandsComma = (amount: number) => {
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const navigateToSales = () => {
    navigate(ROUTES.SALES);
  };

  const showQr = async () => {
    getDynamicQr({
      amount,
      fee: 1.55,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        await getDynamicQr({
          amount,
          fee: 1.55,
        });
      } catch (error) {
        return (
          <ErrorGeneric
            retryFN={() => {
              setShowQrGenerator(false);
            }}
          />
        );
      }
    })();
  }, [amount]);

  useEffect(() => {
    if (data) setDynamicQr(String(`data:image/png;base64,${data}`));
  }, [data]);

  const dimensionScreen = window.innerWidth;

  return (
    <div data-testid="wrap-qr-container">
      <Loader isVisible={loading as boolean} />
      <GoBackContainer
        data-testid="btn-go-back"
        onClick={() => setShowQrGenerator(false)}
        isResponsive>
        <Icon
          iconName={'chevronLeft'}
          width={dimensionScreen < 700 ? '10px' : '20px'}
          height={dimensionScreen < 700 ? '10px' : '20px'}
          fill="blue"
        />
        <GoBackText>Atrás</GoBackText>
      </GoBackContainer>
      <StyledNumericPadCard>
        <Loader isVisible={loading as boolean} />
        <QrGeneratorHeader>
          <Title>Monto a pagar</Title>
          <AmmountContainer>
            <CurrencyText>RD$</CurrencyText>
            <AmmountText>{thousandSeparator(amount)}</AmmountText>
          </AmmountContainer>
        </QrGeneratorHeader>
        <ImgStyled src={dynamicQr} />
        <QrGeneratorFooter>
          <BodyText>Por favor escanea este QR desde tu App Billet para realizar tu pago</BodyText>
          <StyledButtonContainer>
            <Button
              data-testid="btn-validate-sale"
              label="Validar pago"
              onClick={() => navigate(ROUTES.SALES)}
              variant="business"
            />
          </StyledButtonContainer>
        </QrGeneratorFooter>
      </StyledNumericPadCard>
    </div>
  );
};

export default QrGenerator;

import { FC } from 'react';
import {
  StyledErrorCard,
  StyledErrorTitle,
  StyledErrorText,
  StyledErrorImage,
  StyledErrorButton,
  StyledMainContent,
  StyledGoBackContainer,
  StyledGoBackText,
  StyledErrorContainer,
} from './styles';
import mainImage from '../../assets/images/error-generic-image.svg';
import Icon from '../icon';
import { TIconName } from '../icon/types';
import { IErrorGeneric } from './types';

export const ErrorGeneric: FC<IErrorGeneric> = ({ retryFN }) => {
  const dimensionScreen = window.innerWidth;

  return (
    <StyledErrorContainer data-testid="screen-container">
      {dimensionScreen < 700 && (
        <StyledGoBackContainer data-testid="go-back-container" onClick={retryFN}>
          <Icon iconName={'chevronLeft' as TIconName} fill="blue" width="10" height="10" />
          <StyledGoBackText>Atrás</StyledGoBackText>
        </StyledGoBackContainer>
      )}
      <StyledErrorCard>
        <StyledMainContent>
          <StyledErrorTitle>¡Pum! Algo salió mal</StyledErrorTitle>
          <StyledErrorText>
            En estos momentos no pudimos procesar la transacción. Favor intenta más tarde.
          </StyledErrorText>
          <StyledErrorImage src={mainImage} alt="pantalla-error" />
        </StyledMainContent>
        <StyledErrorButton type="button" onClick={retryFN}>
          Volver
        </StyledErrorButton>
      </StyledErrorCard>
    </StyledErrorContainer>
  );
};
